import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import SocialLinks from "../../components/social_links"

const OogtestPage = () => (
  <Layout>
    <Seo title="Oogtest" />
    <section className="page-header">
      <h1>Oogtest</h1>
    </section>    
    <section className="page-intro">
      <div className="page-intro-content">
        <p>Bij Optiek De Leersnijder kan u onder uiterst professionele begeleiding een afspraak maken voor een oogtest. Er wordt gebruik gemaakt van de oogscanner zodat alles precies tot op 1/100 nauwkeurig wordt opgemeten.</p>
        <p className="text-right">
          <SocialLinks iconsOnly={true} wrapped={false} />
        </p>
        <Link to="/contact#afspraak" className="button bg-dl-orange hover:bg-black text-white mr-2" title="Contacteer ons voor een afspraak ...">Afspraak maken</Link>
      </div>
      <div className="col-start-8 lg:col-start-6 col-end-13 lg:col-end-12 row-start-1 row-end-1 z-10 h-2/4 md:h-full flex justify-end items-end">
        <StaticImage
            src="../../images/oogtest_hero.jpg"
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Oogtest"
            placeholder="blurred"
            className="inline-block mx-2 w-12/12 h-full min-h-full"
        />
      </div>
      <div className="section-bg-right col-start-9 lg:col-start-7 col-end-13 -top-16 -right-6 lg:right-4 h-full"></div>
    </section>

    <section className="section my-10">
      <div className="section-content-right">
        <h2 className="pb-8">
          Een brillenwinkel die verder gaat
        </h2>
        <p>De DNEye-scanner doet niet één oogtest. Het apparaat scant het volledige oog met een onschadelijke lichtstraal, waarbij het 1500 hypernauwkeurige metingen verricht.</p>
        <p>Zo wordt duidelijk wat de precieze afwijking is voor verschillende gebieden van uw pupil. Op deze manier kunnen wij perfect inschatten wat u nodig heeft. Of u nu bijziend of verziend bent, een bril, leesbril, lens of zonnebril op sterkte nodig heeft. </p>
        <p className="text-right">
          <SocialLinks iconsOnly={true} wrapped={false} />
        </p>
        <Link to="/contact#afspraak" className="button bg-dl-orange hover:bg-black text-white mr-2" title="Contacteer ons voor een afspraak ...">Afspraak maken</Link>
      </div>
      <div className="col-start-1 col-end-7 row-start-1 row-end-1 z-10 h-full flex justify-start items-center lg:items-end">
        <StaticImage
            src="../../images/oogtest_dneye_01.jpg"
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Oogtest met DNEye-scanner"
            placeholder="blurred"
            className="opacity-0 hidden md:opacity-100 md:inline-block mx-2 w-8/12 h-1/2 lg:h-4/6"
        />
      </div>
      <div className="section-bg-left"></div>
    </section>

    <section className="section">
      {/* <div className="section-content-left col-end-13 md:col-end-10 lg:col-end-10 xl:col-end-8"> */}
      <div className="section-content-left md:col-end-7">
        <h2 className="pb-8">
          Nachtblindheid aangepakt
        </h2>
        <p>Daarenboven kunnen wij bij Optiek De Leersnijder met de DNEye-scanner ook nachtblindheid meten én beduidend verbeteren.</p>
        <p>Wist u dat veel mensen onterecht denken dat ze nachtblind zijn? In werkelijkheid is hun oogafwijking ‘s nachts en tijdens de schemer anders dan overdag. Dit heeft te maken met de grootte van de pupil. De DNEye-scanner bootst verschillende lichtomstandigheden na en meet vervolgens de exacte afwijking bij diverse pupilgroottes. Zo heeft u altijd een optimaal zicht. Zowel overdag, tijdens de schemer als ‘s nachts.</p>
        <p className="text-right">
          <SocialLinks iconsOnly={true} wrapped={false} />
        </p>
        <Link to="/contact#afspraak" className="button bg-dl-orange hover:bg-black text-white mr-2" title="Contacteer ons voor een afspraak ...">Afspraak maken</Link>
      </div>
      <div className="col-start-7 col-end-13 row-start-1 row-end-1 z-10 h-full flex justify-end items-start lg:items-center">
        <StaticImage
            src="../../images/oogtest_dneye_02.jpg"
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Oogtest met DNEye-scanner"
            placeholder="blurred"
            className="opacity-0 hidden md:opacity-100 md:inline-block mx-2 w-8/12 md:h-1/2 lg:h-2/3"
        />
      </div>
      <div className="section-bg-right col-start-8 md:col-start-8 -top-6 md:top-10"></div>
    </section>

  </Layout>
)

export default OogtestPage
